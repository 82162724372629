import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles'
import themeViewnia from "components/utils/Theme"
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import HomeImage from "components/home/HomeImage"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "20%",
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const TIME_OPTIONS = [
  { key: 'half_day', imageUrl: 'half_day_url' },
  { key: 'full_day', imageUrl: 'full_day_url' },
  { key: 'multiple_days', imageUrl: 'multiple_days_url' }
]

function CarouselTimePreferences(props) {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000)
    return () => clearTimeout(timer)
  }, [])

  const renderTimeOption = ({ key, imageUrl }) => (
    <Grid xs={12} sm={6} md={4} item key={key}>
      <Grid
        container
        className="product-timepreference-box"
        onClick={() => window.open(`/products?time=${key}`, '_blank')}
        style={{ cursor: 'pointer' }}
      >
        <Grid xs={5} item>
          <a
            href={`/products?time=${key}`}
            className="flex-center"
            target="_blank"
            title="View Details"
            onClick={(e) => e.stopPropagation()}
          >
            <HomeImage
              home_class="margin-bt-7"
              image_url={props[imageUrl]}
              url={undefined}
              alt={key}
              height={102}
            />
          </a>
        </Grid>
        <Grid xs={7} className="product-timepreferences" item>
          <span className="flex-center-absolute">
            {i18n.t(`excursions.${key}`)}
          </span>
        </Grid>
      </Grid>
    </Grid>
  )

  const renderLoadingSkeleton = () => (
    <Grid container spacing={2}>
      {[1, 2, 3].map((item) => (
        <Grid xs={12} sm={6} md={4} item key={item}>
          <Skeleton variant="rect" className="time-loader" height={102} animation={false} />
        </Grid>
      ))}
    </Grid>
  )

  return (
    <ThemeProvider theme={themeViewnia}>
      <section className="caroussel">
        <section className="products-section">
          {loading ? renderLoadingSkeleton() : (
            <Grid container spacing={2}>
              {TIME_OPTIONS.map(renderTimeOption)}
            </Grid>
          )}
        </section>
      </section>
    </ThemeProvider>
  )
}

CarouselTimePreferences.propTypes = {
  half_day_url: PropTypes.string.isRequired,
  full_day_url: PropTypes.string.isRequired,
  multiple_days_url: PropTypes.string.isRequired
}

export default CarouselTimePreferences