import React, { useState, useEffect } from 'react';

function HomeCover(props) {
  const [cover, setCover] = useState({});
  const [imageSRC, setImage] = useState("");

  useEffect(() => {
    try {
      const covers = typeof props.covers === "string" ? JSON.parse(props.covers) : props.covers;
      console.log("URL de la imagen: ", covers.cover_url);
      setCover(covers);
      setImage(covers.cover_url);

      // Preload dinámico
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = covers.cover_url; // URL dinámica
      link.as = "image";
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link); // Limpieza
      };
    } catch (error) {
      console.error("Error parsing covers JSON: ", error);
      setCover(null);
      setImage(null);
    }
  }, [props.covers]);

  return (
    <section className="carousel">
      <section className="hero-slider ready home-slides-container">
        <article className="slide">
          <div 
            className="background-image skeleton" 
            loading="lazy" 
            style={{
              backgroundImage: `url(${imageSRC})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '459px',
              borderRadius: '12px',
            }}
            role="img"
            aria-label="Imagen de portada"
          ></div>
          <div className="slide-info">
            <p>{cover.message}</p>
            <h1>{cover.title}</h1>
            <a 
              className="call-to-action" 
              href={cover.button_url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {cover.button_message}
            </a>
          </div>
        </article>
      </section>
    </section>
  );
}

export default HomeCover;