import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import HomeCollection from "components/home/HomeCollection";
import Carousel from "components/home/collection_types/Carousel";
import Guidebook from "components/home/collection_types/Guidebook.js.erb";

function Home(props){
  const [home_collections, setHomeCollections] = useState([]);

  useEffect(() => {
    get_home_collections();
  }, []);

  function get_home_collections(){
    return fetch(`home_collections.json`)
      .then(response => response.json())
      .then(json => {
        setHomeCollections(json)
      }
      )
      .catch(error => console.log(error));
  }
  return (
        
    <section className="home-section">
        {home_collections.length > 0 && home_collections.map((home_collection) => {
            const labelId = `home_collection-label-${home_collection.id}`;
            if (home_collection.collection_type == "home_collection"){
              return (
                <HomeCollection 
                  key={labelId}
                  home_collection={home_collection}
                />            
              );
            }else if (home_collection.collection_type == "carousel_region"){
              return (
                <Carousel 
                  carousel_type={home_collection.collection_type}
                />
              );
            }else if (home_collection.collection_type == "carousel_activities"){
              return (
                <Carousel 
                  carousel_type={home_collection.collection_type}
                />
              );
            }else if( home_collection.collection_type == "carousel_time_preference"){
              return (
                <Carousel 
                  carousel_type={home_collection.collection_type}
                />
              );
            }else if( home_collection.collection_type == "subscribe"){
              return (
                <Subscribe 
                  current_user={props.current_user}
                />
              );
            }else if( home_collection.collection_type == "guidebook"){
              return (
                <Guidebook 
                  current_user={props.current_user}
                />
              );
            }else{
              return (
                <Newsletter 
                  current_user={props.current_user}
                />
              );
            }
          })}
    </section>


  );
}

export default Home