import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import {EXDefaultImage} from "components/utils/DefaultImage.js.erb";
import Skeleton from '@material-ui/lab/Skeleton';

function HomeImage(props){

    const [imageSRC, setImage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [loadClass, setLoadClass] = useState("hidden");
    
    useEffect(() => {
 
        setImage(props.image_url)
        
    }, [props.image_url]);

    function onError(){
        setImage(EXDefaultImage)
        setLoaded(true)
        setLoadClass("")
    }

    function onLoad(){
        setLoadClass("")
        setLoaded(true)
    }

    
  return (
    <figure className={props.home_class}>
            <img className={"lazyload " + loadClass} alt="1"
                alt={props.alt ? props.alt : ""}
                src={imageSRC}
                onLoad={onLoad}
                onError={onError}
            />
            {
                !loaded &&
                <Skeleton variant="rect" className="lazyload" height={props.height ? props.height : 188} animation={false} />
            }
        </figure>
  );
}

export default HomeImage

