/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import I18n from 'i18n-js/index.js.erb'
console.log(I18n.t('blog.travel_nature_growth'))
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Función para optimizar la carga del cover
function optimizeCoverLoad() {
  const cover = document.querySelector('.cover');
  if (!cover) return;

  // Crear una imagen temporal para precargar
  const img = new Image();
  img.src = getComputedStyle(cover).backgroundImage.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
  
  img.onload = () => {
    cover.style.opacity = '1'; // Mostrar el cover cuando la imagen esté lista
  };
}

document.addEventListener('DOMContentLoaded', optimizeCoverLoad);

document.addEventListener('DOMContentLoaded', function() {
  // Remover clase loading de forma más eficiente
  requestAnimationFrame(() => {
    document.documentElement.classList.remove('loading');
  });
  
  // Optimizar las transiciones de página
  document.addEventListener('turbolinks:before-render', function() {
    const wrapper = document.querySelector('.content-wrapper');
    if (wrapper) {
      wrapper.classList.add('transitioning');
    }
  });
  
  document.addEventListener('turbolinks:load', function() {
    const wrapper = document.querySelector('.content-wrapper');
    if (wrapper) {
      wrapper.classList.remove('transitioning');
    }
  });
});
