import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import HomeImage from "components/home/HomeImage";

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  function CarouselTimePreferences(props){
    const [loading, setLoading] = useState(true); // Cambiado a true para mostrar el skeleton inicialmente
    const classes = useStyles();
  
    useEffect(() => {
      // Simula la carga de datos
      const timer = setTimeout(() => {
        setLoading(false); // Cambia a false después de 2 segundos
      }, 2000);
      
      return () => clearTimeout(timer); // Limpia el temporizador al desmontar
    }, []);


  
  
    return (
        <ThemeProvider theme={themeViewnia}>
            <section className="caroussel">
                <section className="products-section">
                {loading && 
                    <section id="time-loader" className={classes.root}>     
                    <Skeleton variant="rect" className="time-loader" height={100} animation={false} />
                    <Skeleton variant="rect" className="time-loader" height={100} animation={false} /> 
                    <Skeleton variant="rect" className="time-loader" height={100} animation={false} /> 
                    </section> ||
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={4} item> 
                        <Grid 
                            container 
                            className="product-timepreference-box"
                            onClick={() => window.open('/products?time=half_day', '_blank')}
                            style={{ cursor: 'pointer' }}
                        >
                            <Grid xs={5} item>
                                <a 
                                    href={`/products?time=half_day`} 
                                    className="flex-center" 
                                    target="_blank" 
                                    title="View Details"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <HomeImage
                                        home_class={"margin-bt-7"}
                                        image_url={props.half_day_url}
                                        url={undefined}
                                        alt={"half_day"}
                                    />           
                                </a>
                            </Grid>
                            <Grid xs={7} className="product-timepreferences" item>
                                <span className="flex-center-absolute">{i18n.t("excursions.half_day")}</span>
                            </Grid>
                        </Grid>   
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Grid 
                            container 
                            className="product-timepreference-box"
                            onClick={() => window.open('/products?time=full_day', '_blank')}
                            style={{ cursor: 'pointer' }}
                        >
                            <Grid xs={5} item>
                                <a 
                                    href={`/products?time=full_day`} 
                                    className="flex-center" 
                                    target="_blank" 
                                    title="View Details"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <HomeImage
                                        home_class={"margin-bt-7"}
                                        image_url={props.full_day_url}
                                        url={undefined}
                                        alt={"full_day"}
                                    />    
                                </a>
                            </Grid>
                            <Grid xs={7} className="product-timepreferences" item>
                                <span className="flex-center-absolute">{i18n.t("excursions.full_day")}</span>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Grid 
                            container 
                            className="product-timepreference-box" 
                            onClick={() => window.open('/products?time=multiple_days', '_blank')}
                            style={{ cursor: 'pointer' }}
                        >
                            <Grid xs={5} item>
                                <a 
                                    href={`/products?time=multiple_days`} 
                                    className="flex-center" 
                                    target="_blank" 
                                    title="View Details" 
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <HomeImage
                                        home_class={"margin-bt-7"}
                                        image_url={props.multiple_days_url}
                                        url={undefined}
                                        alt={"multiple_days"}
                                    />     
                                </a>
                            </Grid>
                            <Grid xs={7} className="product-timepreferences" item>
                                <span className="flex-center-absolute">{i18n.t("excursions.multiple_days")}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
                }

           
            </section>
        </section>

    </ThemeProvider>
  );
}

export default CarouselTimePreferences