import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper';
import Rating from '@material-ui/lab/Rating';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';


// Import Swiper styles
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "20%",
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

function CarouselHomeBlog(props){
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    get_posts();
  }, []);

  function get_posts(){
    setLoading(true)
    return fetch(`/posts.json${props.limit ? '?limit='+props.limit : ''}`)
      .then(response => response.json())
      .then(json => {
        setPosts(json.data.posts)
        setLoading(false)
      }
      )
      .catch(error => console.log(error));
  }
  const handleImageError = (e) => {
    e.target.onerror = null; // Evita bucles infinitos en caso de error
  };
  
  return (
    <ThemeProvider theme={themeViewnia}>
    {!props.home_collection_id && (
        <section className="posts">
            <header key={"home_collection_id" + props.home_collection_title}>
                <h2>{props.home_collection_title}</h2>
                <div className="subtitle">{props.home_collection_subtitle}</div>
                <div className="more_index">
                    <a
                        className="more"
                        href={"/blog"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {props.home_collection_link_text}
                    </a>
                </div>
            </header>
            <section className="caroussel">
                <section className="products-section">
                    {loading ? (
                        <section
                            id="skeletons-loader"
                            className={classes.root}
                        >
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                            <Skeleton
                                variant="rect"
                                className="region-loader"
                                width={424}
                                height={275}
                            />
                        </section>
                    ) : (
                        <>
                                <div
                                    className="swiper-button-prev"
                                    ref={navigationPrevRef}
                                ></div>
                            <div
                                className="swiper-button-next"
                                ref={navigationNextRef}
                            ></div>
                            <Swiper
                                navigation
                                mousewheel={true}
                                onSlideChange={() =>
                                    console.log("slide change")
                                }
                                onSwiper={(swiper) =>
                                    console.log("swiper")
                                }
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl =
                                        navigationPrevRef.current;
                                    swiper.params.navigation.nextEl =
                                        navigationNextRef.current;
                                }}
                                breakpoints={{
                                    "425": {
                                        slidesPerView: 1,
                                        spaceBetween: 16,
                                    },
                                    "640": {
                                        slidesPerView: 2,
                                        spaceBetween: 16,
                                    },
                                    "768": {
                                        slidesPerView: 2,
                                        spaceBetween: 16,
                                    },
                                    "1024": {
                                        slidesPerView: 3,
                                        spaceBetween: 16,
                                    },
                                    "1440": {
                                        slidesPerView: 5,
                                        spaceBetween: 16,
                                    },
                                }}
                            >
                                {posts &&
                                    posts.map((post) => {
                                        const labelId = `posts-label-${post.id}`;
                                        return (
                                            <SwiperSlide key={labelId}>
                                                <article className="post-grid">
                                                    <div className="post-wraper">
                                                        <a
                                                            href={`/posts/${post.slug}`}
                                                            title="View Details"
                                                            target="_blank"
                                                            rel="noopener noreferrer" // Buenas prácticas para evitar problemas de seguridad
                                                        >
                                                            {post.cover_f && (
                                                                <figure id="homepost">
                                                                    <img
                                                                        className="lazyload"
                                                                        id="homepost"
                                                                        src={post.cover_f}
                                                                        onError={handleImageError}
                                                                        alt={post.title}
                                                                    />
                                                                </figure>
                                                            )}
                                                            <div className="info-slide text-slide-fix">
                                                                <p className="zone">{post.label}</p>
                                                                <h3>{post.title}</h3>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </article>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </>
                    )}
                </section>
            </section>
        </section>
    )}
</ThemeProvider>
  );
}

export default CarouselHomeBlog
